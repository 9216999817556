import OktaAuth from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { oktaAuth } from "../config/okta";

const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
  window.location.replace(originalUri);
};

const OktaProvider = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default OktaProvider;
