import { useOktaAuth } from "@okta/okta-react";
import { lazy, Suspense } from "react";
import WrapperProvider from "./modules/WrapperProvider";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

const App = () => {
  const { authState } = useOktaAuth();
  const loggedIn = !!authState?.isAuthenticated;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {loggedIn ? (
        <WrapperProvider>
          <AuthenticatedApp />
        </WrapperProvider>
      ) : (
        <WrapperProvider>
          <UnauthenticatedApp />
        </WrapperProvider>
      )}
    </Suspense>
  );
};

export default App;
