import { ValueOpt } from "best-common-react";
import { GameConstants } from "../constants/game";
import { BroadcastSource } from "../types/broadcast";
import { BatterDate, BatterTime } from "../types/core";
import { SportGroup } from "../types/game";
import { DivisionOption, GroupLeagueOption, LeagueDivision } from "../types/league";
import { getTeamName, GroupTeamOption, Team, TeamOption, TimezoneOption } from "../types/team";
import { TimezoneMap } from "../types/timezones.ts";
import { convertNetworkToDisplayName } from "./broadcast";

export const dateOrUndefined = (date?: Date | null) => (!date ? undefined : new BatterDate(date));

export const timeOrUndefined = (time?: Date | null) => (!time ? undefined : new BatterTime(time));

export const createValuesList = <T>(values: ValueOpt<T>[]) => values.map(v => v.value).filter(v => v !== null);

export const toOptions = (...args: string[]): ValueOpt<string>[] => args.map(v => ({ label: v, value: v }));

/**
 * check if an email is a valid email (RFC)
 * @param email email to test
 * @returns true if the email is valid
 */
export const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const teamOptionCreator = (t: Team): TeamOption => ({
  label: getTeamName(t),
  value: t.id,
  team: t
});

export const divisionOptionCreator = (d: LeagueDivision): DivisionOption => ({
  label: d.shortName,
  value: d.id,
  division: d
});

export const timezoneOptionCreator = (tz: TimezoneMap): TimezoneOption => ({
  label: tz.longName,
  value: tz.id,
  timezone: tz
});

export const broadcastSourceOptionCreator = (s: BroadcastSource): ValueOpt<number> => ({
  label: `${s.description}${s.teamName ? " (" + s.teamName + ")" : ""}`,
  value: s.id
});

export const broadcastBucketOptionCreator = (s: BroadcastSource): ValueOpt<number> => {
  const description = convertNetworkToDisplayName(s.description);
  return {
    label: `${description}${s.teamName ? " (" + s.teamName + ")" : ""}`,
    value: s.id
  };
};

export const filterTeamOptionsBySport = (options: GroupTeamOption[], sport: SportGroup) => {
  // create sportIds
  const sportIds = new Set<number>();
  if (sport === "MLB") {
    sportIds.add(GameConstants.SportIds.MLB);
  } else if (sport === "MiLB") {
    GameConstants.SportIds.MiLB.forEach(sportId => sportIds.add(sportId));
  } else if (sport === "WBC") {
    sportIds.add(GameConstants.SportIds.INTERNATIONAL);
  }

  if (!sportIds.size) {
    return [];
  }

  return options.reduce((pv, option) => {
    const currOptions = option.options.filter(o => sportIds.has(o.team?.sportId || -1));
    if (currOptions.length) {
      return [...pv, { ...option, options: currOptions }];
    }
    return [...pv];
  }, [] as GroupTeamOption[]);
};

export const filterTeamOptionsByLeagueIds = (options: GroupTeamOption[], leagueIds: number[]) => {
  const ids = new Set(leagueIds);

  return options.reduce((pv, option) => {
    const currOptions = option.options.filter(o => ids.has(o.team?.leagueId || -1));
    if (currOptions.length) {
      return [...pv, { ...option, options: currOptions }];
    }
    return [...pv];
  }, [] as GroupTeamOption[]);
};

export const filterLeagueOptionsBySport = (options: GroupLeagueOption[], sport: SportGroup) => {
  // create sportIds
  const sportIds = new Set<number>();
  if (sport === "MLB") {
    sportIds.add(GameConstants.SportIds.MLB);
  } else if (sport === "MiLB") {
    GameConstants.SportIds.MiLB.forEach(sportId => sportIds.add(sportId));
  } else if (sport === "WBC") {
    sportIds.add(GameConstants.SportIds.INTERNATIONAL);
  }

  if (!sportIds.size) {
    return [];
  }

  return options.reduce((pv, option) => {
    const currOptions = option.options.filter(
      o =>
        sportIds.has(o.league?.sportId || -1) &&
        (sport !== "WBC" || GameConstants.LeagueIds.WBC_IDS.has(o.league?.id || -1))
    );
    if (currOptions.length) {
      return [...pv, { ...option, options: currOptions }];
    }
    return [...pv];
  }, [] as GroupLeagueOption[]);
};
