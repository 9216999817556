import { BatterUserInfo } from "../types/core";
import { BroadcastUserMapping, TeamUserMapping, UpdateBroadcastMapping, UpdateTeamMapping } from "../types/mappings";
import { batterApi } from "./batter";

export const getUserInfo = async () => {
  return (await batterApi.get<BatterUserInfo>("/v2/users")).data;
};

export const getTeamUserMappings = async () => {
  return (await batterApi.get<{ mappings: TeamUserMapping[] }>("/v2/users/mappings")).data.mappings;
};

export const updateTeamMappings = async (mappings: UpdateTeamMapping[]) => {
  const results = await batterApi.post<{ mappings: TeamUserMapping[] }>("/v2/users/mappings", { mappings });
  return results.data.mappings;
};

export const getBroadcastUserMappings = async () => {
  return (await batterApi.get<{ mappings: BroadcastUserMapping[] }>("/v2/users/broadcastMappings")).data.mappings;
};

export const updateBroadcastMappings = async (mappings: UpdateBroadcastMapping[]) => {
  const results = await batterApi.post<{ mappings: BroadcastUserMapping[] }>("/v2/users/broadcastMappings", {
    mappings
  });
  return results.data.mappings;
};
