import { AlertTypes } from "best-common-react";
import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";

type AlertAction = Action & {
  message?: string;
  variant?: AlertTypes;
  interval?: number;
};

type AlertState = ReactContext<AlertAction> & {
  message: string;
  visible: boolean;
  variant: AlertTypes | null;
  interval: number;
};

const initialState: AlertState = {
  dispatch: () => {},
  message: "",
  visible: false,
  variant: null,
  interval: 5000
};

export const AlertContext = createContext(initialState);

const reducer = (state: AlertState, action: AlertAction): AlertState => {
  switch (action.type) {
    case "setAlert":
      return {
        ...state,
        visible: true,
        message: action.message || "",
        variant: action.variant || null,
        interval: action.interval || 5000
      };
    case "hideAlert":
      return {
        ...state,
        visible: false
      };
    default:
      return state;
  }
};

export const AlertProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AlertContext.Provider value={{ ...state, dispatch }}>{children}</AlertContext.Provider>;
};
