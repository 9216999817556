import { AxiosError } from "axios";
import { useMemo } from "react";
import { oktaAuth } from "../config/okta";
import { RouteConstants } from "../constants/route";
import { LocalStorageConstants } from "../constants/user";
import { ReactProps } from "../types/react";
import { batterApi } from "./batter";

// constants
const AUTH_ERROR_CODES = new Set([401, 403]);

let refreshPromise: Promise<void> | null = null;

// attempt to renew the session.
const renewSession = async () => {
  await oktaAuth.tokenManager.renew("accessToken");
};

// request interceptor
batterApi.interceptors.request.use(config => {
  const tokenStr = localStorage.getItem(LocalStorageConstants.OKTA_TOKEN) || '{"accessToken": "", "idToken": "" }';
  const { accessToken = {} } = JSON.parse(tokenStr);

  const url = config?.url || "";
  if (url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
    config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
  }
  return config;
});

export const HttpInterceptor = ({ children }: ReactProps) => {
  // response interceptor
  useMemo(() => {
    batterApi.interceptors.response.clear();
    batterApi.interceptors.response.use(
      resp => resp || null,
      async (err: AxiosError) => {
        if (AUTH_ERROR_CODES.has(err.response?.status || 0)) {
          // eslint-disable-next-line no-console
          console.log("Token Expired Error. Will renew and retry...");

          // initialize the promise
          if (refreshPromise === null) {
            refreshPromise = renewSession();
          }

          // wait for the promise
          try {
            await refreshPromise;
            refreshPromise = null;
          } catch (error) {
            console.error(error);
            localStorage.clear();
            location.href = RouteConstants.LOGIN.path;
          }
        }
        throw err;
      }
    );
  }, []);

  return <>{children}</>;
};
