import { UserConstants } from "./user";

const {
  ADMIN,
  BROADCAST,
  CLUB,
  EXTERNAL,
  FIELD,
  MINOR_CLUB,
  MINOR_SUPERVISOR,
  SCHEDULE,
  VIEWER,
  BROADCAST_VIEWER,
  BROADCAST_REQUESTER
} = UserConstants.ROLES;

export const RouteConstants = {
  LOGIN: { path: "/login", roles: [] },
  LOGIN_CALLBACK: { path: "/login/callback", roles: [] },
  BASE: { path: "/", roles: [] },
  UNAUTHORIZED: { path: "/unauthorized", roles: [] },
  ADMIN: { path: "/admin", roles: [ADMIN, BROADCAST, SCHEDULE] },
  DOCUMENTS: { path: "/documents", roles: [BROADCAST_VIEWER, CLUB, MINOR_CLUB, MINOR_SUPERVISOR, VIEWER] },
  EVENTS: { path: "/events", roles: [ADMIN, CLUB, FIELD, MINOR_CLUB, MINOR_SUPERVISOR, SCHEDULE] },
  REPORTS: {
    path: "/reports",
    roles: [
      ADMIN,
      BROADCAST,
      BROADCAST_REQUESTER,
      BROADCAST_VIEWER,
      CLUB,
      EXTERNAL,
      FIELD,
      MINOR_CLUB,
      MINOR_SUPERVISOR,
      SCHEDULE,
      VIEWER
    ]
  },
  SCHEDULE: {
    path: "/schedule",
    roles: [ADMIN, BROADCAST, BROADCAST_VIEWER, CLUB, EXTERNAL, FIELD, MINOR_CLUB, MINOR_SUPERVISOR, SCHEDULE, VIEWER]
  },
  IMPORTS: { path: "/imports", roles: [ADMIN, BROADCAST, SCHEDULE] },
  REQUESTS: { path: "/requests", roles: [ADMIN, BROADCAST, BROADCAST_REQUESTER] },
  MEMO: { path: "/memo", roles: [ADMIN, BROADCAST] }
};
