import {
  GeneratedChange,
  IndividualMemoChange,
  MemoChange,
  MemoChangeKey,
  MemoGameAuditChange,
  MemoTeam,
  ParentMemoChange
} from "../types/memo";
import { formatDate, formatDateTime, formatTime, formatTimezone } from "./date";
import { listToDict } from "./list";
import { sanitizeHtml, titleCase } from "./string";

export const calculateMemoKey = ({ changeType, currentChange, previousChange }: IndividualMemoChange) => {
  const key = changeType === "BROADCAST" ? currentChange.broadcastAuditId : currentChange.gameAuditId;
  const prevKey = changeType === "BROADCAST" ? previousChange?.broadcastAuditId : previousChange?.gameAuditId;
  return `${changeType}-${prevKey || 0}-${key}`;
};

export const formatMemoTime = (change: MemoGameAuditChange) => {
  const { gameTime, venueTimezone } = change;
  if (!gameTime) {
    return "TBD";
  }

  return `${formatTime(gameTime, "h:mm a")} ${formatTimezone(venueTimezone, "ZZZZ").replace(/(D|S)/i, "")}`.trim();
};

export const formatChangeTime = (memoChange: MemoChange) => {
  const { gameTime, dateTimeUtc } = memoChange;
  if (!gameTime || !dateTimeUtc) {
    return "TBD";
  }

  return `${formatTime(gameTime, "h:mm a")}/${formatDateTime(dateTimeUtc, "h:mm a 'ET'", { toZone: "America/New_York" })}`;
};

export const formatMemoDoubleHeader = (change: MemoChange) => {
  switch (change.doubleHeader) {
    case "S":
      return "Split";
    case "T":
    case "Y":
      return "Traditional";
    case "N":
      return "No";
  }
};

const createChangeKeyFromParentMemoChange = ({
  changeType,
  previousId,
  currentId
}: ParentMemoChange): MemoChangeKey => ({ changeType, previousId, currentId });

export const calculateMemoKeyForParent = ({ changeType, previousId, currentId }: ParentMemoChange) => {
  return `${changeType}-${previousId}-${currentId}`;
};

export const convertToChangeKeys = (changes: MemoChange[]) => {
  return changes
    .flatMap(change => change.changes)
    .map((c): MemoChangeKey => {
      const { changeType, currentChange, previousChange, parent, isMerged = false, html = "" } = c;

      return {
        html,
        changeType,
        merged: isMerged,
        parent: parent ? createChangeKeyFromParentMemoChange(parent) : undefined,
        currentId: changeType === "BROADCAST" ? currentChange.broadcastAuditId : currentChange.gameAuditId,
        previousId: changeType === "BROADCAST" ? previousChange?.broadcastAuditId : previousChange.gameAuditId
      };
    });
};

export const generateChanges = (change: IndividualMemoChange) => {
  // instance
  const results: GeneratedChange[] = [];
  const { changeType, currentChange, previousChange } = change;

  // based on changeType, create nodes
  if (changeType === "SCHEDULE") {
    const dateChange = previousChange.gameDate !== currentChange.gameDate;
    const timeChange = previousChange.gameTime !== currentChange.gameTime;
    const venueChange = previousChange.venueId !== currentChange.venueId;

    // if there is a date AND a time change, push a custom change
    if (dateChange && timeChange) {
      const { gameDate: prevDate } = previousChange;
      const { gameDate: toDate } = currentChange;
      const prevTime = formatMemoTime(previousChange);
      const toTime = formatMemoTime(currentChange);
      const from = prevTime === "TBD" && !prevDate ? "TBD" : `${formatDate(prevDate, "M/d")} ${prevTime}`;
      const to = toTime === "TBD" && !toDate ? "TBD" : `${formatDate(toDate, "M/d")} ${toTime}`;

      results.push({
        type: "game",
        operation: "change",
        from,
        to
      });
    } else {
      if (dateChange) {
        results.push({
          type: "date",
          operation: "change",
          from: formatDate(previousChange.gameDate, "M/d/yy", "TBD"),
          to: formatDate(currentChange.gameDate, "M/d/yy", "TBD")
        });
      }

      if (timeChange) {
        results.push({
          type: "time",
          operation: "change",
          from: formatMemoTime(previousChange),
          to: formatMemoTime(currentChange)
        });
      }
    }

    if (venueChange) {
      results.push({
        type: "venue",
        operation: "change",
        from: previousChange.venueName || "TBD",
        to: currentChange.venueName || "TBD"
      });
    }
  }

  if (changeType === "BROADCAST") {
    if (!previousChange) {
      results.push({
        type: "broadcaster",
        operation: "added",
        broadcastTeam: currentChange.broadcastTeam,
        value: currentChange.sourceName || String(currentChange.sourceId) || "broadcast source"
      });
    } else if (previousChange && !currentChange.sourceId) {
      results.push({
        type: "broadcaster",
        operation: "removed",
        broadcastTeam: previousChange.broadcastTeam,
        value: previousChange.sourceName || String(previousChange.sourceId) || "broadcast source"
      });
    } else if (previousChange && previousChange.sourceId !== currentChange.sourceId) {
      results.push({
        type: "broadcaster",
        operation: "change",
        broadcastTeam: previousChange.broadcastTeam,
        from: previousChange.sourceName || String(previousChange.sourceId) || "broadcast source",
        to: currentChange.sourceName || String(currentChange.sourceId) || "broadcast source"
      });
    }
  }

  return results;
};

export const replaceMemoChangesWithMergedChanges = (
  mergedChanges: IndividualMemoChange[],
  changesToReplace: MemoChange[]
) => {
  // NOTE: these should all have the same batterPk
  if (mergedChanges.some(c => c.batterPk !== mergedChanges[0].batterPk)) {
    throw new Error("All changes must have the same batterPk");
  }

  const batterPk = mergedChanges[0].batterPk!;
  const changeMap = listToDict(mergedChanges, calculateMemoKey);

  return changesToReplace.map(p => {
    if (p.batterPk !== batterPk) {
      return p;
    }

    // for the specific batterPk, update the changes
    const newChanges = p.changes.map(c => {
      const key = calculateMemoKey(c);
      const value = changeMap[key];
      if (value) {
        delete changeMap[key];
        return value;
      }
      return c;
    });

    // append the remaining changes
    newChanges.unshift(...Object.values(changeMap));

    return { ...p, changes: newChanges };
  });
};

export const removeMergedChangeFromChanges = (
  changeToRemove: IndividualMemoChange,
  changesToUpdate: MemoChange[],
  changesToEnable?: IndividualMemoChange[]
) => {
  // instance
  const batterPk = changeToRemove.batterPk!;

  // NOTE: these should all have the same batterPk
  if (changesToEnable && changesToEnable.some(c => c.batterPk !== changesToEnable[0].batterPk)) {
    throw new Error("All changes must have the same batterPk");
  }

  const removeKey = calculateMemoKey(changeToRemove);
  const changeMap = listToDict(changesToEnable || [], calculateMemoKey);

  return changesToUpdate.map(p => {
    if (p.batterPk !== batterPk) {
      return p;
    }

    return {
      ...p,
      changes: p.changes
        .filter(c => calculateMemoKey(c) !== removeKey)
        .map(c => {
          if (changesToEnable) {
            return changeMap[calculateMemoKey(c)] || c;
          }

          if (!c.parent) {
            return c;
          }

          const parentKey = `${c.parent.changeType}-${c.parent.previousId}-${c.parent.currentId}`;
          return parentKey === removeKey ? { ...c, parent: undefined, isMerged: false } : c;
        })
    };
  });
};

const generateHTMLTeamPrefix = (arg?: MemoTeam) => {
  if (!arg?.abbrev) {
    return "";
  }

  const { abbrev, localSourceNames } = arg;
  if (!localSourceNames) {
    return abbrev + ", ";
  }

  return `<span>${abbrev} <span style="font-weight: bold;">(${localSourceNames.join(", ")})</span>, </span>`;
};

export const generateHTMLChange = (change: IndividualMemoChange) => {
  const text = generateChanges(change)
    .map(value => {
      return value.operation === "change"
        ? `${generateHTMLTeamPrefix(value.broadcastTeam)}${titleCase(value.type)} change from ${value.from} to <span style="font-weight: bold;">${value.to}</span>`
        : `${generateHTMLTeamPrefix(value.broadcastTeam)}${value.operation} <span style="font-weight: bold;">${value.value}</span>`;
    })
    .join(", ");
  return sanitizeHtml(`<div>${text}</div>`);
};
