import { ValueOpt } from "best-common-react";
import parser, { CronExpression } from "cron-parser";
import { daysOfTheWeek } from "../constants/form";
import { joinListGrammatically } from "../utils/string";

export type Hydrates = {
  hydrates?: string[];
};

export type UserInfo = {
  accessToken: { accessToken: string };
  userName?: string;
  userFullName?: string;
  email?: string;
  orgId?: number;
  orgName?: string;
  orgShortName?: string;
  userId?: number;
  permissions?: string[];
};

export type RoleInfo = {
  adminUser: boolean;
  assignedClub: boolean;
  broadcastUser: boolean;
  broadcastRequester: boolean;
  broadcastViewerUser: boolean;
  clubUser: boolean;
  externalUser: boolean;
  fieldUser: boolean;
  milbUser: boolean;
  minorClubUser: boolean;
  minorClubSupervisorUser: boolean;
  scheduleUser: boolean;
  viewerUser: boolean;
  roles: Set<string>;
};

export class BatterDate {
  year: number;
  month: number;
  day: number;
  constructor(info?: Date) {
    const date = info || new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;
    this.day = date.getDate();
  }
}

export class BatterTime {
  hour: number;
  minute: number;
  second: number;
  constructor(info?: Date) {
    this.hour = info?.getHours() || 0;
    this.minute = info?.getMinutes() || 0;
    this.second = info?.getSeconds() || 0;
  }
}

export class BatterDateTime {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  constructor(info?: Date) {
    this.year = info?.getFullYear() || 0;
    this.month = (info?.getMonth() || -1) + 1;
    this.day = info?.getDate() || 0;
    this.hour = info?.getHours() || 0;
    this.minute = info?.getMinutes() || 0;
    this.second = info?.getSeconds() || 0;
  }
}

export type BatterUserInfo = {
  name: string;
  username: string;
  roles: string[];
};

export type BatterApiError = {
  error: string;
  errors?: unknown[];
  message: string;
  path: string;
  status: number;
  timestamp: string;
  trace?: string;
};

export type BatterDatePickerValue = {
  startDate?: Date | null;
  endDate?: Date | null;
  tbd: boolean;
};

export type GroupOption<T> = {
  label: string;
  options: ValueOpt<T>[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ElementType = React.ReactElement<any, string | React.JSXElementConstructor<any>>;

export type ViewType = "calendar" | "table" | "grid";

export type CronTimeObject = {
  [x: number]: boolean;
};

type MutableFields = {
  second: number[];
  minute: number[];
  hour: number[];
  dayOfMonth: number[] | "L";
  month: number[];
  dayOfWeek: number[];
};

export type CronType = "unix" | "spring";

export class BatterCron {
  private _fields: MutableFields;
  private _cronExpression: CronExpression;

  constructor(cron: string) {
    this._cronExpression = parser.parseExpression(cron.replace(/\?/g, "*"));
    this._fields = JSON.parse(JSON.stringify(this._cronExpression.fields));

    // set the days of the week
    this._fields.dayOfWeek = this.modifyDaysOfWeek(this._fields.dayOfWeek);
  }

  get hours(): number[] {
    return this._fields.hour;
  }

  set hours(hours: number | number[]) {
    const val = Array.isArray(hours) ? hours : [hours];
    const hasDecimal = val.some(i => !Number.isInteger(i));
    if (hasDecimal) {
      throw new Error("Only integers from 0-23 allowed");
    }

    if (Math.min(...val) < 0 || Math.max(...val) > 23) {
      throw new Error("values not in hour range");
    }
    this._fields.hour = val;
  }

  get daysOfWeek(): number[] {
    return this._fields.dayOfWeek;
  }

  set daysOfWeek(daysOfWeek: number | number[]) {
    const val = Array.isArray(daysOfWeek) ? daysOfWeek : [daysOfWeek];
    const hasDecimal = val.some(i => !Number.isInteger(i));
    if (hasDecimal) {
      throw new Error("Only integers from 0-7 allowed");
    }

    if (Math.min(...val) < 0 || Math.max(...val) > 7) {
      throw new Error("values not in days of week range");
    }
    this._fields.dayOfWeek = val;
  }

  clone = () => new BatterCron(this.asExpression());

  /**
   * Returns cron as a string
   * @param type CronType ('unix'|'spring')
   * @returns
   */
  asExpression = (type: CronType = "unix") => {
    this._cronExpression = parser.fieldsToExpression(this._fields as parser.CronFields);
    const prefix = type === "unix" ? "" : "0 ";
    return prefix + this._cronExpression.stringify();
  };

  /**
   * displays a human-readable cron description
   *
   * NOTE: only shows data for hours and the days of the week
   * @returns english string of the cron
   */
  description = () => {
    // get unix expr
    const unixExpr = this.asExpression().split(" ");

    // get hour description
    const hourDesc = this.getHourDescription(unixExpr[1]);

    // get day of week description
    const dayWeekDesc = this.getDaysOfWeekDescription(unixExpr[4]);
    return `${dayWeekDesc} ${hourDesc}`;
  };

  /**
   * in cron expressions, 0 or 7 are treated as Sunday. if 7
   * is present, we will remove the 7 and replace with a 0.
   * @param value days of the week as a number array
   */
  private modifyDaysOfWeek = (value: number[]) => {
    const days = new Set(value);
    if (days.has(7)) {
      days.delete(7);
      days.add(0);
    }

    // return the days as a sorted number array
    return Array.from(days).sort();
  };

  private getHourDescription = (unixExpr: string) => {
    if (unixExpr === "*") {
      return "Every hour";
    }

    if (unixExpr.includes("/")) {
      const [_, rate] = unixExpr.split("/");
      const rateInt = parseInt(rate, 10);
      return rateInt === 1 ? "Every hour" : `every ${rateInt} hours`;
    }

    const hours = unixExpr.split(",");
    let firstOfKind = "";
    const tokens = hours.map(h => {
      if (h.includes("-")) {
        const [start, end] = h.split("-");
        const prefix = firstOfKind !== "between" ? "between " : "";
        firstOfKind = "between";
        return `${prefix}${this.toHourString(start)} & ${this.toHourString(end)}`;
      }

      const prefix = firstOfKind !== "at" ? "at " : "";
      firstOfKind = "at";
      return `${prefix}${this.toHourString(h)}`;
    });

    return joinListGrammatically(tokens);
  };

  private getDaysOfWeekDescription = (unixExpr: string) => {
    if (unixExpr === "*") {
      return "daily";
    }

    if (unixExpr.includes("/")) {
      const [_, rate] = unixExpr.split("/");
      const rateInt = parseInt(rate, 10);
      return rateInt === 1 ? "daily" : `every ${rateInt} days`;
    }

    const days = unixExpr.split(",");
    const tokens = days.map(h => {
      if (h.includes("-")) {
        const [start, end] = h.split("-");
        return `${this.toDayOfWeekString(start)} through ${this.toDayOfWeekString(end)}`;
      }
      return this.toDayOfWeekString(h);
    });

    return joinListGrammatically(tokens);
  };

  private toHourString = (value: number | string) => {
    const hour = typeof value === "string" ? parseInt(value, 10) : value;
    const res = hour % 12 || 12;
    const meridian = hour < 12 ? "am" : "pm";
    return `${res}:00 ${meridian}`;
  };

  private toDayOfWeekString = (value: number | string) => {
    const day = (typeof value === "string" ? parseInt(value, 10) : value) % 7;
    return daysOfTheWeek[day];
  };
}

BatterCron.prototype.toString = function () {
  return this.asExpression();
};
