import { Alert, AlertTypes } from "best-common-react";
import { useContext, useEffect, useRef } from "react";
import Fade from "react-bootstrap/Fade";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { AlertContext } from "../contexts/alert";
import { randomString } from "../utils/string";

const AlertBox = styled.div`
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1066;

  &&& {
    .best-alert {
      margin: 0;
      max-width: 400px;
      max-height: 150px;
      overflow-y: auto;
    }
  }
`;

const Content = styled.div`
  font-size: 0.875rem;
  letter-spacing: -0.35px;
  width: 100%;
  padding-right: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

const alertId = `global-alert-${randomString(7)}`;

const GlobalAlert = () => {
  // hooks
  const timeout = useRef(0);
  const containerRef = useRef(document.createElement("div"));
  const { visible, message, variant, interval, dispatch } = useContext(AlertContext);

  // effects
  // handle show/hide of alert
  useEffect(() => {
    if (visible) {
      window.clearTimeout(timeout.current);

      if (!!interval && interval > 0) {
        timeout.current = window.setTimeout(() => {
          dispatch({ type: "hideAlert" });
        }, interval);
      }
    }
  }, [visible, interval, dispatch]);

  // append itself to the DOM
  useEffect(() => {
    const el = containerRef.current;
    el.id = "batter-alert-container";
    document.body.appendChild(el);

    return () => {
      clearTimeout(timeout.current);
      document.body.removeChild(el);
    };
  }, []);

  return ReactDOM.createPortal(
    <Fade in={visible} unmountOnExit>
      <AlertBox>
        <Alert
          id={alertId}
          type={variant as AlertTypes}
          onRemove={() => {
            clearTimeout(timeout.current);
            dispatch({ type: "hideAlert" });
          }}
        >
          <Content>
            {message.split("\n").map((m, i) => (
              <div key={i}>{m}</div>
            ))}
          </Content>
        </Alert>
      </AlertBox>
    </Fade>,
    containerRef.current
  );
};

export default GlobalAlert;
