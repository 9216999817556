import { ValueOpt } from "best-common-react";
import { BroadcastRequestStatus } from "../types/broadcast";
import { GameChange, SportGroup } from "../types/game";
import { MemoChangeType, MemoStatus } from "../types/memo";
import { BroadcastAvailabilityOption } from "../types/request";

export const daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const daysOfTheWeekAbbr = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const dayOptions = [...daysOfTheWeek, "Weekday", "Weekend"].map(
  v => ({ label: v, value: v }) as ValueOpt<string>
);

export const sportOptions: ValueOpt<SportGroup>[] = [
  { label: "MLB", value: "MLB" },
  { label: "MiLB", value: "MiLB" },
  { label: "WBC", value: "WBC" }
];

export const ExpandedSportOptions: ValueOpt<number>[] = [
  { label: "MLB", value: 1 },
  { label: "Triple A", value: 11 },
  { label: "Double A", value: 12 },
  { label: "Single A", value: 13 },
  { label: "International", value: 51 },
  { label: "Futures", value: 21 }
];

export const yesNoOptions: ValueOpt<boolean>[] = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

export const trueFalseOptions: ValueOpt<boolean>[] = [
  { value: true, label: "True" },
  { value: false, label: "False" }
];

export const doubleHeaderOptions: ValueOpt<string>[] = [
  { label: "Not a Doubleheader", value: "N" },
  { label: "Traditional", value: "Y" },
  { label: "Split", value: "S" }
];

export const gameChangeOptions: ValueOpt<GameChange>[] = [
  { label: "Postponed", value: "POSTPONED" },
  { label: "Suspended", value: "SUSPENDED" }
];

export const broadcastRequestOptions: ValueOpt<BroadcastRequestStatus>[] = [
  { label: "Approved", value: "APPROVED" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Submitted", value: "SUBMITTED" }
];

export const broadcastAvailabilityOptions: ValueOpt<BroadcastAvailabilityOption>[] = [
  { label: "All", value: "ALL" },
  { label: "None", value: "NONE" },
  { label: "Broadcasters", value: "BROADCASTERS" }
];

export const memoStatusOptions: ValueOpt<MemoStatus>[] = [
  { label: "Unreleased", value: "UNRELEASED" },
  { label: "Released", value: "RELEASED" },
  { label: "Scheduled", value: "SCHEDULED" }
];

export const memoChangeTypeOptions: ValueOpt<MemoChangeType>[] = [
  { label: "Schedule", value: "SCHEDULE" },
  { label: "Broadcast", value: "BROADCAST" }
];

export const memoChangeArchiveOptions: ValueOpt<0 | 1 | 2>[] = [
  { label: "Hide Archived Changes", value: 0 },
  { label: "Include Archived Changes", value: 1 },
  { label: "Show Only Archived Changes", value: 2 }
];

export const memoChangeOutOfMarketOptions: ValueOpt<0 | 1 | 2>[] = [
  { label: "Hide Out-of-Market Changes", value: 0 },
  { label: "Include Out-of-Market Changes", value: 1 },
  { label: "Show Only Out-of-Market Changes", value: 2 }
];
