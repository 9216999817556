import { ValueOpt } from "best-common-react";
import { GameConstants } from "../constants/game";
import { GroupOption } from "./core";
import { SportGroup, SportType } from "./game";
import { TimezoneMap } from "./timezones.ts";

export interface TeamOption extends ValueOpt<number> {
  team?: Team;
}

export interface GroupTeamOption extends GroupOption<number> {
  options: TeamOption[];
}

export interface Team {
  id: number;
  teamId: number;
  teamInfoId: number;
  leagueId?: number;
  leagueAbbrev?: string;
  divisionId?: number;
  teamCode: string;
  teamAbbrev: string;
  teamName: string;
  teamNameShort: string;
  fullTeamName?: string;
  formattedName?: string;
  sportId?: number;
  sportName?: string;
  sportType?: SportType;
  venueId?: number;
  stVenueId?: number;
  timezoneId?: number;
  isPlaceholder?: boolean;
  year?: number;
  parentOrgId?: number;
  startYear: number;
  endYear?: number | null;
}
export interface TeamRequest {
  id?: number | null;
  leagueId?: number | null;
  league?: string | null;
  divisionId?: number | null;
  division?: string | null;
  teamCode: string;
  teamAbbrev: string;
  teamName: string;
  teamNameShort: string;
  fullTeamName?: string | null;
  formattedName?: string | null;
  sportId?: number | null;
  venueId?: number | null;
  stVenueId?: number | null;
  timezoneId?: number | null;
  year?: number;
  parentOrgId?: number | null;
  startYear: number;
  endYear?: number | null;
}

export type TimezoneOption = ValueOpt<number> & {
  timezone?: TimezoneMap;
};

export const getTeamName = (t: Team) => t.formattedName || t.fullTeamName || `${t.teamNameShort} ${t.teamName}`;

export const getTeamSportGroup = (t: Team): SportGroup | null => {
  if (GameConstants.LeagueIds.WBC_IDS.has(t.leagueId || 0)) {
    return "WBC";
  }

  if (GameConstants.LeagueIds.FUTURES_IDS.has(t.leagueId || 0)) {
    return "Futures";
  }

  if (t.sportType === "MAJOR") {
    return "MLB";
  }

  if (t.sportType === "MINOR") {
    return "MiLB";
  }

  return null;
};
