export const GameConstants = {
  GameTypes: {
    ALL: "All",
    REGULAR_SEASON: "R",
    POST_SEASON: "P",
    SPRING_TRAINING: "S"
  },
  GameStatus: {
    FINAL: "F",
    CANCELLED: "C",
    POSTPONED: "D",
    SCHEDULED: "S",
    IN_PROGRESS: "I",
    COMPLETE: "S,F,I,P",
    PREGAME: "P",
    SUSPENDED: "U",
    AVAILABLE: "S,D,F,I,P"
  },
  AuditReasons: {
    DELETED: 1,
    CREATED: 2,
    UPDATED: 3,
    CANCELED: 4,
    DUPLICATE: 5,
    WEATHER: 6,
    COVID_19: 7,
    RESCHEDULED: 8,
    OTHER: 9
  },
  SportIds: {
    MLB: 1,
    TRIPLE_A: 11,
    DOUBLE_A: 12,
    HIGH_A: 13,
    SINGLE_A: 14,
    INTERNATIONAL: 51,
    MiLB: new Set([11, 12, 13, 14]),
    FUTURES: 21
  },
  LeagueIds: {
    WBCQ: 159,
    WBC: 160,
    WBC_IDS: new Set([159, 160]),
    FUTURES_IDS: new Set([590])
  },
  TeamIds: {
    MLB_ALL_STAR: new Set([159, 160])
  }
};
