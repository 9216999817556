export const range = (start: number, end?: number) => {
  if (end == null) {
    return Array.from(Array(start).keys());
  }

  return Array.from(Array(end - start).keys()).map(i => i + start);
};

export const getOrdinal = (x: number) => {
  switch (x) {
    case 11:
    case 12:
    case 13:
      return x + "th";
    default: {
      const base10 = x % 10;
      if (base10 === 1) {
        return x + "st";
      }
      if (base10 === 2) {
        return x + "nd";
      }
      if (base10 === 3) {
        return x + "rd";
      }
      return x + "th";
    }
  }
};

/**
 * Promise-based version of setTimeOut
 * @param ms number of milliseconds to sleep
 * @returns
 */
export const sleep = (ms: number) => new Promise<void>(r => setTimeout(r, ms));

/**
 * check if an object is empty
 * @param obj the object to check
 * @returns true if the object is empty
 */
export const isObjectEmpty = (obj: object) => Object.keys(obj).length === 0;

const isInfinite = (x: unknown) => !Number.isFinite(x);

export const compareValue = <T extends string | number>(a?: T | null, b?: T | null) => {
  if (a == null && b == null) {
    return 0;
  }

  if (a == null && b != null) {
    return -1;
  }

  if (a != null && b == null) {
    return 1;
  }

  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }

  if (typeof a === "number" && typeof b === "number") {
    // special case: handle infinity
    if (isInfinite(a) && !isInfinite(b)) return 1;
    if (!isInfinite(a) && isInfinite(b)) return -1;
    if (isInfinite(a) && isInfinite(b)) return 0;

    // everything else
    return a - b;
  }

  throw Error("types mismatch");
};
