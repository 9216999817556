import { ValueOpt } from "best-common-react";
import { createContext, useReducer } from "react";
import {
  BroadcastMethod,
  BroadcastRequest,
  BroadcastSource,
  BroadcastSourceBucket,
  BroadcastType,
  Language,
  NationalQualifier,
  SapLanguage
} from "../types/broadcast";
import { Action, ReactContext, ReactProps } from "../types/react";
import { compareValue } from "../utils/core";
import { broadcastSourceOptionCreator } from "../utils/form";
import { listToDict, upsert } from "../utils/list";

type BroadcastAction = Action & {
  broadcastTypes?: BroadcastType[];
  broadcastMethods?: BroadcastMethod[];
  broadcastSources?: BroadcastSource[];
  broadcastSourceBuckets?: BroadcastSourceBucket[];
  nationalQualifiers?: NationalQualifier[];
  languages?: Language[];
  sapLanguages?: SapLanguage[];
  broadcastRequest?: BroadcastRequest;
  broadcastRequests?: BroadcastRequest[];
};

type BroadcastState = ReactContext<BroadcastAction> & {
  broadcastTypes: BroadcastType[];
  broadcastMethods: BroadcastMethod[];
  broadcastSources: BroadcastSource[];
  broadcastSourceBuckets: BroadcastSourceBucket[];
  broadcastRequests: BroadcastRequest[];
  sourceById: { [x: number]: BroadcastSource };
  sourceBucketsById: { [x: number]: BroadcastSourceBucket };
  nationalQualifiers: NationalQualifier[];
  languages: Language[];
  languageByName: { [x: string]: Language };
  sapLanguages: SapLanguage[];
  sapLanguageByCode: { [x: string]: Language };
  broadcastTypeOptions: ValueOpt<number>[];
  broadcastSourceOptions: ValueOpt<number>[];
  broadcastMethodOptions: ValueOpt<number>[];
  broadcastSourceBucketOptions: ValueOpt<number>[];
  languageOptions: ValueOpt<number>[];
  sapLanguageOptions: ValueOpt<number>[];
  nationalQualifierById: { [x: number]: NationalQualifier };
  nationalQualifierOptions: ValueOpt<number>[];
  activeSourceOptions: ValueOpt<number>[];
  activeNationalSourceOptions: ValueOpt<number>[];
  activeNationalTvSourceOptions: ValueOpt<number>[];
  outOfMarketSourceOptions: ValueOpt<number>[];
};

const initialState: BroadcastState = {
  dispatch: () => {},
  broadcastTypes: [],
  broadcastMethods: [],
  broadcastSources: [],
  broadcastSourceBuckets: [],
  broadcastRequests: [],
  sourceById: {},
  sourceBucketsById: {},
  nationalQualifiers: [],
  languages: [],
  languageByName: {},
  sapLanguages: [],
  sapLanguageByCode: {},
  broadcastTypeOptions: [],
  broadcastSourceOptions: [],
  broadcastMethodOptions: [],
  broadcastSourceBucketOptions: [],
  languageOptions: [],
  sapLanguageOptions: [],
  nationalQualifierById: {},
  nationalQualifierOptions: [],
  activeSourceOptions: [],
  activeNationalSourceOptions: [],
  activeNationalTvSourceOptions: [],
  outOfMarketSourceOptions: []
};

export const BroadcastContext = createContext(initialState);

const createSourceOptions = (sources: BroadcastSource[]): Partial<BroadcastState> => {
  const activeSources = sources.filter(s => s.isActive);
  const activeNationalSources = activeSources.filter(s => s.broadcastRegion === "National");
  return {
    broadcastSources: sources,
    sourceById: listToDict(sources, s => s.id),
    broadcastSourceOptions: sources.map(broadcastSourceOptionCreator),
    activeSourceOptions: activeSources.map(broadcastSourceOptionCreator),
    activeNationalSourceOptions: activeNationalSources.map(broadcastSourceOptionCreator),
    activeNationalTvSourceOptions: activeNationalSources.filter(s => s.type === "TV").map(broadcastSourceOptionCreator),
    outOfMarketSourceOptions: sources.filter(s => s.isOutOfMarket).map(broadcastSourceOptionCreator)
  };
};

const reducer = (state: BroadcastState, action: BroadcastAction): BroadcastState => {
  switch (action.type) {
    case "setBroadcastTypes": {
      const { broadcastTypes = [] } = action;
      return {
        ...state,
        broadcastTypes,
        broadcastTypeOptions: broadcastTypes.map(t => ({ value: t.id, label: t.type }))
      };
    }
    case "setBroadcastMethods": {
      const { broadcastMethods = [] } = action;
      return {
        ...state,
        broadcastMethods,
        broadcastMethodOptions: broadcastMethods.map(m => ({ value: m.id, label: m.method }))
      };
    }
    case "setBroadcastSources":
      return {
        ...state,
        ...createSourceOptions(action.broadcastSources || [])
      };
    case "setBroadcastSourceBuckets": {
      const { broadcastSourceBuckets = [] } = action;
      return {
        ...state,
        broadcastSourceBuckets,
        sourceBucketsById: listToDict(broadcastSourceBuckets, s => s.id),
        broadcastSourceBucketOptions: broadcastSourceBuckets.map(m => ({
          value: m.id,
          label: m.name
        }))
      };
    }
    case "setLanguages": {
      const { languages = [] } = action;
      return {
        ...state,
        languages,
        languageOptions: languages.map(l => ({ value: l.id, label: l.language })),
        languageByName: listToDict(languages, l => l.language)
      };
    }
    case "setSapLanguages": {
      const { sapLanguages = [] } = action;
      return {
        ...state,
        sapLanguages,
        sapLanguageOptions: sapLanguages.map(l => ({ value: l.id, label: l.language })),
        sapLanguageByCode: listToDict(sapLanguages, l => l.initial)
      };
    }
    case "setNationalQualifiers": {
      const { nationalQualifiers = [] } = action;
      return {
        ...state,
        nationalQualifiers,
        nationalQualifierById: nationalQualifiers.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        nationalQualifierOptions: nationalQualifiers.map(q => ({ value: q.id, label: q.description }))
      };
    }
    case "setBroadcastMetaData": {
      const {
        broadcastSources = [],
        broadcastTypes = [],
        broadcastMethods = [],
        broadcastSourceBuckets = [],
        nationalQualifiers = [],
        languages = [],
        sapLanguages = []
      } = action;
      return {
        ...state,
        ...createSourceOptions(broadcastSources),
        broadcastTypes,
        broadcastMethods,
        broadcastSourceBuckets,
        nationalQualifiers,
        languages,
        sapLanguages,
        broadcastTypeOptions: broadcastTypes.map(t => ({ value: t.id, label: t.type })),
        broadcastMethodOptions: broadcastMethods.map(m => ({ value: m.id, label: m.method })),
        broadcastSourceBucketOptions: broadcastSourceBuckets.map(m => ({
          value: m.id,
          label: m.name
        })),
        languageOptions: languages.map(l => ({ value: l.id, label: l.language })),
        sapLanguageOptions: sapLanguages.map(l => ({ value: l.id, label: l.language })),
        nationalQualifierById: nationalQualifiers.reduce((pv, c) => ({ ...pv, [c.id]: c }), {}),
        nationalQualifierOptions: nationalQualifiers.map(q => ({ value: q.id, label: q.description }))
      };
    }
    case "setBroadcastRequests": {
      const { broadcastRequests = [] } = action;
      return {
        ...state,
        broadcastRequests: broadcastRequests.sort((a, b) => {
          const dateRes = compareValue(a.primaryGame.dateTimeUtc, b.primaryGame.dateTimeUtc);
          if (dateRes !== 0) {
            return dateRes;
          }

          return compareValue(a.primaryGame.homeTeamId, b.primaryGame.homeTeamId);
        })
      };
    }
    case "saveBroadcastRequest": {
      const { broadcastRequests } = state;
      const { broadcastRequest } = action;

      if (!broadcastRequest) {
        return state;
      }

      return {
        ...state,
        broadcastRequests: upsert(broadcastRequest, broadcastRequests, b => b.id === broadcastRequest.id)
      };
    }
    case "deleteBroadcastRequest": {
      return {
        ...state,
        broadcastRequests: state.broadcastRequests.filter(b => b.id !== action.broadcastRequest?.id)
      };
    }
    default:
      return state;
  }
};

export const BroadcastProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <BroadcastContext.Provider value={{ ...state, dispatch }}>{children}</BroadcastContext.Provider>;
};
