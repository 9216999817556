import { ValueOpt } from "best-common-react";
import { titleCase } from "../utils/string";

const roles = [
  "BATTER_ADMIN",
  "BATTER_BROADCAST",
  "BATTER_BROADCAST_REQUESTER",
  "BATTER_BROADCAST_VIEWER",
  "BATTER_CLUB",
  "BATTER_EXTERNAL",
  "BATTER_FIELD",
  "BATTER_MINOR_CLUB",
  "BATTER_MINOR_SUPERVISOR",
  "BATTER_SCHEDULING",
  "BATTER_VIEWER"
] as const;
export type BatterRole = (typeof roles)[number];
export const BATTER_ROLES = roles as unknown as BatterRole[];

export const roleOptionCreator = (r: BatterRole): ValueOpt<BatterRole> => ({
  value: r,
  label: titleCase(r.replace(/^BATTER_/i, ""), "_", " ")
});

export const UserConstants = {
  ROLES: {
    ADMIN: "BATTER_ADMIN" as BatterRole,
    BROADCAST: "BATTER_BROADCAST" as BatterRole,
    BROADCAST_VIEWER: "BATTER_BROADCAST_VIEWER" as BatterRole,
    BROADCAST_REQUESTER: "BATTER_BROADCAST_REQUESTER" as BatterRole,
    CLUB: "BATTER_CLUB" as BatterRole,
    EXTERNAL: "BATTER_EXTERNAL" as BatterRole,
    FIELD: "BATTER_FIELD" as BatterRole,
    MINOR_CLUB: "BATTER_MINOR_CLUB" as BatterRole,
    MINOR_SUPERVISOR: "BATTER_MINOR_SUPERVISOR" as BatterRole,
    SCHEDULE: "BATTER_SCHEDULING" as BatterRole,
    VIEWER: "BATTER_VIEWER" as BatterRole
  },
  ROLE_OPTIONS: [...roles]
    .filter(r => r !== "BATTER_ADMIN")
    .sort()
    .map(roleOptionCreator),
  CLUB_USERS: ["BATTER_CLUB", "BATTER_MINOR_CLUB"] as BatterRole[]
};

export const LocalStorageConstants = {
  BATTER_ROLES: "batter-roles",
  BATTER_USERNAME: "batter-username",
  OKTA_TOKEN: "okta-token-storage"
};
