/**
 * Turns a list into a map of string | number => value
 * @param list list to reduce
 * @param keyFunc a function that takes a value and returns the key
 * @returns
 */
export const listToDict = <T, U extends string | number>(list: T[], keyFunc: (t: T) => U) => {
  return list.reduce((pv, c) => ({ ...pv, [keyFunc(c)]: c }), {} as Record<U, T>);
};

/**
 * Turns a list into a map of string | number => value[]
 * @param list list to reduce
 * @param keyFunc a function that takes a value and returns the key
 * @returns
 */
export const listToGroupDict = <T, U extends string | number>(list: T[], keyFunc: (t: T) => U) => {
  return list.reduce(
    (pv, c) => {
      const key = keyFunc(c);
      const arr: T[] = pv[key] || [];
      arr.push(c);

      return {
        ...pv,
        [key]: arr
      };
    },
    {} as Record<U, T[]>
  );
};

/**
 * Replace an item in a list, returning a new list
 * @param list original list
 * @param item item to add
 * @param index the index to change
 * @returns a new list
 */
export const replaceItem = <T>(list: T[], item: T, index: number) => {
  return [
    ...list
      .slice(0, index)
      .concat([item])
      .concat(list.slice(index + 1))
  ];
};

/**
 * upsert a value into a list, either replacing the original/updating
 * @param value the value to upsert
 * @param list the list to upsert the value into
 * @param keyFunc a function to check if the value is in the list
 * @returns a new upserted list
 */
export const upsert = <T>(value: T, list: T[], keyFunc: (t: T) => boolean) => {
  if (!list.length) {
    return [value];
  }

  const copy = [...list];
  const idx = list.findIndex(keyFunc);
  if (idx === -1) {
    copy.push(value);
  } else {
    copy[idx] = value;
  }

  return copy;
};

/**
 * remove a value from a list by index
 * @param list the list to remove the item from
 * @param index the index that you wish to remove
 * @returns
 */
export const removeAtIndex = <T>(list: T[], index: number) => {
  return [...list.slice(0, index).concat(list.slice(index + 1))];
};

/**
 * compare two lists for equality
 * @param list1 the first list
 * @param list2 the second list
 * @param compareFunc an optional function that returns true if the items are equal
 * @returns
 */
export const compareLists = <T>(list1: T[], list2: T[], compareFunc?: (t1: T, t2: T) => boolean) => {
  return (
    list1.length === list2.length && list1.every((v, i) => (compareFunc ? compareFunc(v, list2[i]) : v === list2[i]))
  );
};
