export const getBranch = () => {
  switch (import.meta.env.VITE_ENV) {
    case "prod":
      return "main";
    case "stg":
      return "stage";
    case "dev":
      return "develop";
    default:
      return import.meta.env.VITE_BRANCH;
  }
};
