import { createContext, useReducer } from "react";
import { EventFilters, NonBaseballEvent } from "../types/event";
import { Action, ReactContext, ReactProps } from "../types/react";

type EventAction = Action & {
  events?: NonBaseballEvent[];
  selectedEvent?: NonBaseballEvent;
  eventFilters?: EventFilters;
  fromSearch?: boolean;
  isCreatingNew?: boolean;
};

type EventState = ReactContext<EventAction> & {
  events: NonBaseballEvent[];
  selectedEvent: NonBaseballEvent | null;
  eventFilters: EventFilters;
  fromSearch: boolean;
  isCreatingNew: boolean;
};

const initialState: EventState = {
  dispatch: () => {},
  events: [],
  selectedEvent: null,
  eventFilters: {},
  fromSearch: false,
  isCreatingNew: false
};

export const EventContext = createContext(initialState);

const reducer = (state: EventState, action: EventAction): EventState => {
  switch (action.type) {
    case "selectEvent":
      return {
        ...state,
        selectedEvent: action.selectedEvent || null,
        isCreatingNew: false
      };
    case "setCreatingNew":
      return {
        ...state,
        isCreatingNew: true,
        selectedEvent: null
      };
    case "setInfoAfterSave":
      return {
        ...state,
        isCreatingNew: false,
        selectedEvent: action.selectedEvent || null,
        events: action.events || state.events
      };
    case "setEventsAfterSearch":
      return {
        ...state,
        isCreatingNew: false,
        fromSearch: true,
        selectedEvent: null,
        eventFilters: action.eventFilters || {},
        events: action.events || []
      };
    case "reset":
      return initialState;
    default:
      return state;
  }
};

export const EventProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <EventContext.Provider value={{ ...state, dispatch }}>{children}</EventContext.Provider>;
};
