import { ValueOpt } from "best-common-react";
import { createContext, useReducer } from "react";
import { Action, ReactContext, ReactProps } from "../types/react";
import { Schedule, ScheduleStatus, ScheduleType } from "../types/schedule";
import { toOptions } from "../utils/form";
import { listToDict } from "../utils/list";

type ScheduleAction = Action & {
  schedules?: Schedule[];
  scheduleTypes?: ScheduleType[];
  scheduleEditors?: string[];
  scheduleStatuses?: ScheduleStatus[];
};

type ScheduleState = ReactContext<ScheduleAction> & {
  schedules: Schedule[];
  scheduleTypes: ScheduleType[];
  scheduleEditors: string[];
  scheduleEditorOptions: ValueOpt<string>[];
  scheduleStatuses: ScheduleStatus[];
  scheduleTypesByCode: { [k: string]: ScheduleType };
  scheduleStatusesByText: { [k: string]: ScheduleStatus };
};

const initialState: ScheduleState = {
  dispatch: () => {},
  schedules: [],
  scheduleTypes: [],
  scheduleEditors: [],
  scheduleEditorOptions: [],
  scheduleStatuses: [],
  scheduleTypesByCode: {},
  scheduleStatusesByText: {}
};

export const ScheduleContext = createContext(initialState);

const reducer = (state: ScheduleState, action: ScheduleAction): ScheduleState => {
  switch (action.type) {
    case "setSchedules":
      return {
        ...state,
        schedules: action.schedules || []
      };
    case "setScheduleEditors":
      return {
        ...state,
        scheduleEditors: action.scheduleEditors || [],
        scheduleEditorOptions: toOptions(...(action.scheduleEditors || []))
      };
    case "setScheduleMetaData": {
      const { scheduleTypes = [], scheduleStatuses = [] } = action;
      return {
        ...state,
        scheduleTypes,
        scheduleStatuses,
        scheduleTypesByCode: listToDict(scheduleTypes, s => s.code),
        scheduleStatusesByText: listToDict(scheduleStatuses, s => s.status)
      };
    }
    default:
      return state;
  }
};

export const ScheduleProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <ScheduleContext.Provider value={{ ...state, dispatch }}>{children}</ScheduleContext.Provider>;
};
